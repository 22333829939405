import {
  AuthApiError,
  OktaAuth,
  OktaAuthOptions,
  SignoutOptions,
} from '@okta/okta-auth-js';
import { set as idbSet } from 'idb-keyval';
import { env } from 'src/api/hosts';

interface EnvironmentSpecificOptions {
  issuerPath: `https://${string}/oauth2/${string}`;
  issuerId: string;
  clientId: string;
}

const environmentSpecificOptions: Readonly<{
  [Environment: string]: EnvironmentSpecificOptions;
}> = {
  prd: {
    clientId: '0oa71l0qjr9dmPn1v4x7',
    issuerPath: 'https://apps.motional.com/oauth2/',
    issuerId: 'aus71kv8xktOUzCOP4x7',
  },
  com: {
    clientId: '0oado1gorqubzyoiA4x7',
    issuerPath: 'https://apps.motional.com/oauth2/',
    issuerId: 'ausdo1ds94iarNz4F4x7',
  },
  dev: {
    clientId: '0oa3k66s07vz97uqG1d7',
    issuerPath: 'https://motional.oktapreview.com/oauth2/',
    issuerId: 'aus3k64vnhcspKf3r1d7',
  },
} as const;

const authOptions =
  (env && environmentSpecificOptions[env]) || environmentSpecificOptions.dev;
const OKTA_CONFIG: OktaAuthOptions = {
  clientId: authOptions.clientId,
  issuer: `${authOptions.issuerPath}${authOptions.issuerId}`,
  scopes: ['openid', 'email', 'profile', 'offline_access'],
  redirectUri: `${window.location.origin}/callback`,
  // Get the username/password from localStore when running cypress tests
  tokenManager:
    window['Cypress'] ?
      {
        storage: 'localStorage',
        storageKey: 'oktaCypress',
      }
    : {
        autoRenew: true,
      },
};

const SIGNOUT_OPTIONS: SignoutOptions = {
  postLogoutRedirectUri: window.location.origin,
};

export const oktaAuth = new OktaAuth(OKTA_CONFIG);

oktaAuth.tokenManager.on('renewed', (key, token) => {
  idbSet(key, token);
});

oktaAuth.tokenManager.on('added', (key, token) => {
  idbSet(key, token);
});

export const oktaLogout = async () => {
  try {
    await oktaAuth?.signOut(SIGNOUT_OPTIONS);
  } catch (_error) {
    const error = _error as AuthApiError;
    alert(
      `Attempt to log out failed with the following error:\n${error.name} — ${
        error.message ??
        (error.xhr as Error | undefined)?.message ??
        'something went wrong'
      }\n\nPlease wait a minute as you may still be logged out. If not, contact support.`,
    );
  }
};
