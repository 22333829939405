import { captureException } from '@sentry/browser';
import { onlineManager } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import createStore from 'src/components/FleetMonitoring/create-store';
import { trackAction } from 'src/tools/analytics';

const MAX_FAILD_REQUESTS = 4;

// onlineManager doesn’t work in Safari, so we manually check requests
export const useFailedRequestStore = createStore({
  latestFailedRequests: [] as string[],
});

// these are extracted to prevent spamming the useEffect nor globally spam capture exception
let disconnectionWasReported = false;
useFailedRequestStore.subscribe((state) => {
  if (state.latestFailedRequests.length <= MAX_FAILD_REQUESTS) {
    disconnectionWasReported = false;
    return;
  }

  if (disconnectionWasReported) {
    return;
  }

  disconnectionWasReported = true;
  const message = `Connection was deemeed unstable due to the following failed/slow requests:
  - ${useFailedRequestStore.getState().latestFailedRequests.join('\n  - ')}`;

  trackAction('Connectivity', { action: 'Went offline', value: message });
  captureException(new Error(message));
});

// onlineManager doesn’t have unsubscribe,
//   and onlineManager.listeners is marked as protected,
//   so implement our own outside of react-land
type Listener = (newIsOnline: boolean) => void;
const listeners: Set<Listener> = new Set();
let isOnline = onlineManager.isOnline();
onlineManager.subscribe((newIsOnline) => {
  isOnline = newIsOnline;
  listeners.forEach((listener) => listener(newIsOnline));

  if (!newIsOnline) {
    trackAction('Connectivity', {
      action: 'Went offline',
      value: 'Lost internet connectivity',
    });
  }
});

export const useHasInternetConnectivity = () => {
  const { latestFailedRequests } = useFailedRequestStore();
  const [hasInternetConnectivity, setHasInternetConnectivity] =
    useState(isOnline);

  useEffect(function syncConnectivityState() {
    const handleConnectivityChange = (isOnline: boolean) => {
      setHasInternetConnectivity(isOnline);
    };

    listeners.add(handleConnectivityChange);

    return () => {
      listeners.delete(handleConnectivityChange);
    };
  }, []);

  return (
    hasInternetConnectivity && latestFailedRequests.length <= MAX_FAILD_REQUESTS
  );
};
