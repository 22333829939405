import {
  SummaryState,
  TaskEventName,
} from '@motional-cc/fe/interface/api/api-concierge';
import ReactGA from 'react-ga4';
import { UaEventOptions } from 'react-ga4/types/ga4';
import { MapLayer } from 'src/components/common/Select/SelectMapLayers';
import { AppSection } from 'src/components/Dashboard/DashboardApp';
import { SHOULD_TRACK } from 'src/screens/Analytics';

type CarId = string;

interface AvailableTracks {
  'Bundles page': {
    'Click create new': null;

    'Toggle bundle row': `${'Open' | 'Close'}: ${string}`; // new state: bundle name
    'Click save new': 'save failed' | 'invalid form' | 'success';

    'Click deploy icon': string; // bundle name
    'Search AV vehicle': string; // search term
    'Search AV-RVA vehicle': string; // search term
    'Change search term': string; // search term

    'Change label filter': string; // comma separated list of labels names
    'Sorted table': `${string}:${'asc' | 'desc' | 'none'}`; // column name:sort direction
    'Close create new form': null;
    'Close deploy form': null;
    'Register bundle': 'save failed' | 'invalid form' | 'success';
    'Click clone a bundle': string;
  };

  'Component labels page': {
    'Add label click': null; // label
    'Create new label': 'save failed' | 'invalid form' | 'success'; // label
    'Change label filter': string; // label
    'Change search term': string; // search term
    'Confirmed does not deploy handling': null;
    'Delete label': 'save failed' | 'invalid form' | 'success';
    'View label details click': `${string}:${string}`; // name:version
  };

  'Vehicle config': {
    'Downloaded CSV': null;
    'Scroll to bottom of table': null;

    'Sorted table': `${string}:${'asc' | 'desc' | 'none'}`; // column name:sort direction

    'Searched table': string; // search term
    'Filtered table': string; // column name
    'Copied for Jira': string; // vehicle ID

    'Click reset to default columns': null;
    'Close customize columns form': null;
    'Change customize column': `${'Checked' | 'Unchecked'}: ${string}`; // new_state: component_name
  };

  'Sub-app navigation': {
    'Go to Dashboard': AppSection;
    'Navigate via header': AppSection;
    'Navigate via dashboard': AppSection;
  };

  'Manage Armada': {
    'Change filter': `${string} => ${string} ${string} ${string}`; // old key =>  new_key new_check new_values
    'Remove filter': `${string} ${string} ${string}`; // old_key old_check old_values
  };

  'Vehicle partners': {
    'Click edit partner': null;
  };

  'Vehicle dispatch': {
    'Dispatch on ride': `${CarId} => ${string}; ${string}`; // carId => from; to
    'Dispatch on plan': `${CarId} => ${string}`; // carId => from; to; when; from; to
    'Dispatch on route': `${CarId} => ${string}/${string}/${string}`; // carId => directory/file/route
    'Dispatch to pudo': `${string}`; // pudo
    'Dispatch to route engine': `${string}`; // pudo

    'Dispatch via waypoints': `${string}`; // waypoints
    'Dispatch manually via waypoints': `${string}`; // waypoints

    'Dispatch preposition': `${string} => ${
      | 'in random order'
      | 'sequentially'}; ${'stopping at goals' | 'stopless'}; ${
      | 'with laps'
      | 'once'}`;

    'Dispatch to CC PuDos - stopping at goals': `${string} => ${
      | 'with laps'
      | 'once'}`;
  };

  'Dispatch tab': {
    'Dispatch via JSON': `${CarId} => ${string}` | 'FAILED' | 'INVALID'; // `carId => geojson name
    'Dispatch via CC PuDos':
      | `${CarId} => ${string}`
      | 'FAILED'
      | `INVALID - ${string}` // `INVALID - reason
      | 'CANCEL RIDE'; // `carId => pudo ID array
    'Dispatch on Ride':
      | `${CarId} => ${string}; ${string}`
      | `INVALID - ${string}` // `INVALID - reason
      | 'FAILED'
      | 'CANCEL RIDE';
    'Dispatch on Plan':
      | CarId
      | `INVALID - ${string}` // `INVALID - reason
      | 'FAILED'
      | 'CANCEL PLAN'
      | 'CANCEL FAILED'
      | 'CANCEL RIDE';
    'Dispatch through Route Engine Package':
      | `${CarId} => ${string}` // carId => route package name
      | `INVALID - ${string}`
      | 'FAILED'
      | 'CANCEL RIDE'; // INVALID - reason
    'Dispatch on Route Engine Route':
      | `${CarId} => ${string}` // carId => route id
      | `INVALID - ${string}` // INVALID - reason
      | 'FAILED'
      | 'CANCEL RIDE';
    'Dispatch on Manual Route':
      | `${CarId} => ${string}` // carId => last waypoint
      | `INVALID - ${string}` // INVALID - reason
      | 'FAILED'
      | 'CANCEL RIDE';
  };

  'Config check': {
    'Run config check': `${CarId} => ${string}, ${string}`; // carId => dt link, dt status
  };

  'Fleet monitoring': {
    'Change fleet filter':
      | `${string}:${string}`
      | `${string}:${string} => ${string}`; // filter_name:value => boolean
    'Remove fleet filter': null;
    'Vehicle clicks from list': `${CarId}`;
    'Change vehicle list search term': `${string}`;
    'Click on vehicle from map': `${CarId}`;
    'Click on layer filter': `${MapLayer}`;
    'Click action item: toggle accepting ride': `${CarId}`;
    'Click action item: map': `${CarId}`;
    'Click action item: message': `${CarId}`;
    'Click action item: cancel': `${CarId}`;
    'Click action item: dispatch': `${CarId}`;
    'Expand vehicle status option': null;
    'Expand rva session details toggle': `${CarId}`;
    'Assignment edit': `${CarId}`;
    'Dispatch rideshare': `${CarId}`;
    'Toggle rideshare': `${CarId}`;
    'Click on view full details': `${CarId}`;
    'Change detail tab': `${CarId} => ${string}`; // carId => tab
    'Click on summary state filter from full overview': `${SummaryState}`;
    'Change trip and RVA search term': string;
  };

  'Quick report': {
    Cancelled: string;
    Succeeded: string;
    Failed: string;
  };

  'Fleet management': {
    // Current assignments
    'Click navigate to fleet monitoring': `${CarId}`;
    'Change current assignments search term': string;

    // Bundles
    'Toggle vehicle details': `${'Open' | 'Close'}: ${string}`; // new state: vehicle name
    'Change bundles search term': string;
  };

  'My vehicle': {
    'Select role': string;
    'Select vehicle': string;
    'Deselect vehicle': null;
    'Check in': null;
  };

  'Ride tasks': {
    'Task not skipped':
      | 'MISSING NAME'
      | `FAILED => ${TaskEventName}`
      | `MISSING PROCEED EVENT => ${TaskEventName}`
      | `MISSING REASON => ${TaskEventName}`;
    'Skip task': TaskEventName;
  };

  Connectivity: {
    'Went offline': string; // most recent bad calls
  };
}

function internalTrackAction<
  Category extends keyof AvailableTracks,
  Action extends string & keyof AvailableTracks[Category],
>(
  category: Category,
  {
    action,
    value,
  }: null extends AvailableTracks[Category][Action] ?
    Exclude<AvailableTracks[Category][Action], null> extends string | number ?
      {
        action: Action;
        value?: AvailableTracks[Category][Action];
      }
    : {
        action: Action;
        value?: never;
      }
  : {
      action: Action;
      value: AvailableTracks[Category][Action];
    },
) {
  if (!SHOULD_TRACK) return;

  const event: UaEventOptions = {
    category,
    action,
  };

  if (typeof value !== 'undefined') {
    if (typeof value === 'number') {
      event.value = value;
    } else if (typeof value === 'string') {
      event.label = value;
    }
  }

  ReactGA.event(event);
}

export const trackAction = internalTrackAction;
