import {
  FloatingPortal,
  Placement,
  arrow,
  autoPlacement,
  flip,
  offset,
  shift,
  useFloating,
  useHover,
  useInteractions,
  useRole,
  autoUpdate,
} from '@floating-ui/react';
import clsx from 'clsx';
import {
  CSSProperties,
  Children,
  ReactElement,
  ReactNode,
  cloneElement,
  useImperativeHandle,
  useState,
} from 'react';
import './Tooltip.scss';

export interface Props {
  placement?: Placement[] | Placement | undefined;
  children: ReactElement;
  content?: ReactNode | null;
  size?: 'medium' | 'small';
  open?: boolean;
}

function Tooltip({
  placement,
  children,
  content,
  size = 'medium',
  open: isOpenProps,
}: Props) {
  const [childElt, setChildElt] = useState<HTMLElement | null>(null);
  const [arrowElt, setArrowElt] = useState<HTMLDivElement | null>(null);
  const [isOpenInternal, setIsOpenInternal] = useState(false);
  const child = Children.only(children);
  useImperativeHandle(child?.['ref'], () => childElt);

  const isOpen = isOpenProps ?? isOpenInternal;
  const middleware = [offset(10), shift()];
  if (arrowElt) {
    middleware.push(arrow({ element: arrowElt }));
  }
  if (!placement) {
    middleware.push(autoPlacement());
  } else if (Array.isArray(placement)) {
    middleware.push(autoPlacement({ allowedPlacements: placement }));
  } else {
    middleware.push(flip());
  }

  const floatingUi = useFloating<HTMLElement>({
    open: isOpen,
    onOpenChange:
      typeof isOpenProps === 'boolean' ? undefined : (
        (open) => setIsOpenInternal(open)
      ),
    placement: !Array.isArray(placement) ? placement : undefined,
    strategy: 'fixed',
    middleware,
    whileElementsMounted: autoUpdate,
    elements: {
      reference: childElt,
    },
  });

  const { getFloatingProps } = useInteractions([
    useRole(floatingUi.context, { role: 'tooltip' }),
    useHover(floatingUi.context, { delay: { open: 300 } }),
  ]);

  if (!child || !content) {
    return child;
  }

  const style: CSSProperties = {
    position: floatingUi.strategy,
    left: floatingUi.x ?? 0,
    top: floatingUi.y ?? 0,
  };

  return (
    <>
      {cloneElement(child, {
        ref: setChildElt,
      })}

      <FloatingPortal>
        {isOpen && (
          <div
            ref={floatingUi.refs.setFloating}
            style={style}
            className="the-tooltip theme--inverted"
            {...getFloatingProps()}
          >
            <div
              ref={setArrowElt}
              style={
                {
                  '--offset-x': `${floatingUi.middlewareData.arrow?.x}px`,
                  '--offset-y': `${floatingUi.middlewareData.arrow?.y}px`,
                } as CSSProperties
              }
              className={clsx([
                'the-tooltip__arrow',
                `the-tooltip__arrow--${floatingUi.placement}`,
              ])}
            />
            <div
              className={clsx(
                'the-tooltip__content',
                `the-tooltip__content--size-${size}`,
              )}
            >
              {content}
            </div>
          </div>
        )}
      </FloatingPortal>
    </>
  );
}

export default Tooltip;
